@tailwind base;
@tailwind components;
@tailwind utilities;

/* import local fonts css */
/* @import url("./assets/fonts/stylesheet.css"); */
/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;1,400;1,600&display=swap");

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-custom {
  /* font-family: "Oswald", sans-serif; */
}

.animate-text-line {
  animation: text-line 18s linear both infinite;
}
@keyframes text-line {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

body {
  /* background-color: #0023f5; */
  background: #0023f5 url("./assets/setters/bg-mobile.png") no-repeat top center;
  background-size: cover;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  min-height: 100vh;
}

@media (min-width: 1024px) {
  body {
    background: #0023f5 url("./assets/setters/bg-desktop.png") no-repeat center
      center;
    background-size: cover;
  }
  .full-container-h {
    min-height: calc(100vh - 112px);
  }
}

/* .octagons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
.octagons.left {
  background: url('./assets/octogons_left.svg') no-repeat left center;
  background-size: auto 100%;
}
.octagons.right {
  background: url('./assets/octogons_right.svg') no-repeat right center;
  background-size: auto 100%;
} */

#root {
  position: relative;
}

#starfield {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

.animate-show-in-opacity {
  animation: show-in-opacity 5s ease both;
}

.animate-show-in-from-top {
  animation-name: show-in-from-top;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.animate-swipe-left {
  animation: swipe-left 2s linear infinite;
}

@keyframes swipe-left {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-128px, 0);
  }
}

.animation-delay-100 {
  animation-delay: 100ms;
}
.animation-delay-150 {
  animation-delay: 150ms;
}
.animation-delay-200 {
  animation-delay: 200ms;
}
.animation-delay-250 {
  animation-delay: 250ms;
}
.animation-delay-300 {
  animation-delay: 300ms;
}
.animation-delay-350 {
  animation-delay: 350ms;
}
.animation-delay-400 {
  animation-delay: 400ms;
}
.animation-delay-450 {
  animation-delay: 450ms;
}
.animation-delay-500 {
  animation-delay: 500ms;
}
.animation-delay-600 {
  animation-delay: 600ms;
}
.animation-delay-700 {
  animation-delay: 700ms;
}

@keyframes show-in-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-in-from-top {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 
.scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgb(17, 24, 39);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgb(250, 204, 21);
  border-radius: 100vh;
  border: 3px solid rgb(17, 24, 39)
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(229, 186, 13)
} */
